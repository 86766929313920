<template>
  <div class="select_city_page">
    <div v-if="userInfo && !userInfo.province">
      <h2>完善高考信息</h2>
      <p class="sub_title gray">保存后，不可修改，请认真选择</p>
      <div>
        <div class="cell_label">高考省份</div>
        <van-cell
          :title="cityValue || '请点击选择'"
          icon="location-o"
          is-link
          @click="cityPopupShow = true"
        />
      </div>
      <div class="finish_select_city">
        <el-button type="primary" @click="selectArea">完成</el-button>
      </div>
      <van-action-sheet
        v-model="cityPopupShow"
        class="city_modal"
        title="请选择高考省份"
        @cancel="cityPopupShow = false"
      >
        <ul>
          <li
            v-for="(item, index) in areaOptions"
            :key="index"
            :class="{ 'active_city': cityValue == item.name }"
            @click="cityChange(item)"
          >
            {{ item.name }}
          </li>
        </ul>
      </van-action-sheet>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      userInfo: state => state.userinfo,
      currentProvince: state => state.currentProvince,
      currentYear: state => state.currentYear,
    })
  },

  data() {
    return {
      cityValue: "",
      cityPopupShow: false,
      areaOptions: [],
      isNew: false
    };
  },
  mounted() {
    this.getProvince();
  },
  methods: {
    async getProvince() {
      let provinceList = await this.$axios.get("/api/province/");
      this.areaOptions = provinceList.data.results.results;
    },
    cityChange(item) {
      console.log("选择省份", item.name);
      this.cityValue = item.name;
      this.isNew = item.is_new;
      this.cityPopupShow = false;
    },
    async selectArea() {
      if (!this.cityValue) {
        this.$toast("请选择高考省份");
        return false;
      }
      const apiUrl = "/api/user/update_province/";
      try {
        const res = await this.$axios.post(apiUrl, {
          province: this.cityValue,
          is_new: this.isNew
        });
        localStorage.setItem("localProvince", this.cityValue);
        if (res.data.errno === 0) {
          this.$store.dispatch("getUserInfo");
          // this.$router.push({ path: "/fillScore/create" });
        }
      } catch (error) {
        // console.log(error);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.select_city_page {
  height: 100vh;
  padding: 51px 0.45rem;
  h2 {
    font-size: 0.38rem;
    margin-top: 0.4rem;
  }
  .sub_title {
    margin: 0.2rem 0 0.6rem 0;
  }
  .cell_label {
    margin: 0 0 0.2rem 0;
  }
  .city_modal {
    height: 60%;
    ul {
      padding: 0.2rem 0;
      li {
        height: 40px;
        line-height: 40px;
        text-align: center;
        padding: 0 0.5rem;
        border-bottom: 1px solid #f4f4f4;
      }
      .active_city {
        color: #910000;
      }
    }
  }
  .finish_select_city {
    position: absolute;
    bottom: 2rem;
    left: 0;
    width: 100%;
    padding: 0 0.45rem;
    .el-button {
      width: 100%;
    }
  }
}
</style>
